<template>
  <div class="home">
    <div v-if="authorized">
    <!-- 第一个容器 -->
    <div class="container">
      <div class="item" v-for="(item, index) in items" :key="index">
        <div class="content" :class="{ 'blink': index == num }" >
          <img :src="item.image" alt="Image" class="image">
          <div class="value">{{ item.count }}</div>
        </div>
      </div>
    </div>
    <!-- 第二个容器 -->
    <div class="second-container">
      <div class="side-image">
        <img src="https://api.yy-nuoya.com/m3.png" alt="Side Image">
      </div>
      <div class="side-content">
        <div class="time-content">
          <div v-for="(item, index) in list[3]" :key="index" class="time-item">
            {{ item.formattedTime }}
          </div>
        </div>
      </div>
    </div>
    <div class="second-container">
      <div class="side-image">
        <img src="https://api.yy-nuoya.com/m4.png" alt="Side Image">
      </div>
      <div class="side-content">
        <div class="time-content">
          <div v-for="(item, index) in list[4]" :key="index" class="time-item">
            {{ item.formattedTime }}
          </div>
        </div>
      </div>
    </div>
    <div class="second-container">
      <div class="side-image">
        <img src="https://api.yy-nuoya.com/m5.png" alt="Side Image">
      </div>
      <div class="side-content">
        <div class="time-content">
          <div v-for="(item, index) in list[5]" :key="index" class="time-item">
            {{ item.formattedTime }}
          </div>
        </div>
      </div>
    </div>
    <div class="second-container">
      <div class="side-image">
        <img src="https://api.yy-nuoya.com/m6.png" alt="Side Image">
      </div>
      <div class="side-content">
        <div class="time-content">
          <div v-for="(item, index) in list[6]" :key="index" class="time-item">
            {{ item.formattedTime }}
          </div>
        </div>
      </div>
    </div>
    <div class="header">
       <div class="header-item—list" v-for="(item,index) in newitem" :key="index">
          <img :src="item.Image" alt="" class="img">
          <div class="tooltip-container">
              <div class="username">{{item.username}}</div>
              <div class="username">{{item.pirce}}</div>
          </div>
          <div class="count">{{ item.OpenTime }}</div>
       </div>
    </div>
  </div>
    <div v-else class="fullscreen-danmaku">
      <div class="danmaku-message">
         {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import { getRequest } from '@/services/httpService';
import WebSocketService from '@/services/webSocketService';

export default {
  name: 'Home',
  data() {
    return {
      items: [],
      responseData: [],
      list:[],
      newitem:[],
      wsService: null,
      num:'',
      authorized:false,
      message:''
    };
  },
  mounted() {
    // 发送 GET 请求
    this.checkAuthorization()
    setTimeout(()=>{
          // 监听 WebSocket 消息
      this.wsService.socket.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          // 处理消息
          if(message.type === 'F'){
            this.items[message.digit].count++
            this.num = message.digit
            const img = 'https://api.yy-nuoya.com/m' + message.digit +".png"
            message.Image = img;
            this.newitem.unshift(message)
            if(message.digit == 4 || message.digit == 3 || message.digit == 5 || message.digit == 6){
              this.list[message.digit].push({
                formattedTime:message.OpenTime
              })
            }
          }
        } catch (error) {
        }
      };
    },2000)
  },
  methods: {
    async checkAuthorization() {
      try {
        const response = await getRequest('/checkip.php');
        this.authorized = response.authorized
        if(this.authorized){
          this.fetchData()
          this.connectWebSocket()
        }
        this.message = response.message
       
      } catch (error) {
        console.error('Error checking authorization:', error);
        return false;
      }
    },
    connectWebSocket() {
      this.wsService = new WebSocketService('wss://yy-nuoya.com:8080');
      this.wsService.connect();
    },
    async fetchData() {
      try {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); 
        const day = now.getDate().toString().padStart(2, '0');
        const currentDate = `${year}-${month}-${day}`;
        const data = await getRequest('/', { time: currentDate });
        this.newitem = data.encryptedData.info
        this.items = data.encryptedData.numberData
        this.list = data.encryptedData.timeRecords
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
  },
};
</script>


<style lang="less" scoped>
.home {
  position: relative;
}

.fullscreen-danmaku {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  overflow: hidden;
}

.danmaku-message {
  position: absolute;
  white-space: nowrap;
  animation: scroll-left 10s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.header-item—list{
    width: 12%;
    text-align: center;
    padding: 10px;
    font-weight: 700;
    position: relative;
}
.count {
    border-radius: 10%;
    color: red;
}
.username{
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tooltip-container {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 80px;
    height: 30px;
    background-color: #3498db;
    color: #fff;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    z-index: 1;
    opacity: 1;
    transition: opacity .3s ease;
}
.img{
  max-width: 80px;
  height: 80px;
}
.header{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  border-radius: 5px;
  margin-top: 30px;
}
.header-item-list{
  width: 12%;
  text-align: center;
  padding: 10px;
  font-weight: 700;
  position: relative;
}
.time-item{
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    width: 68px;
    color: #000;
}
.time-content{
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  overflow-x: auto;
}
.container {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px; /* 设置最大宽度，以便在大屏幕上不会拉伸过宽 */
  margin: auto;
}

.item {
  text-align: center;
  margin: 10px;
  flex-basis: calc(25% - 20px); /* 每行四个，计算基础宽度 */
}

.content {
  position: relative; /* 让图片和数字相对定位 */
}

.blink .image {
  animation: blink 1s infinite alternate; /* 添加闪烁动画 */
}

.image {
  width: 100%;
  height: auto;
  border-radius: 10px; /* 图片圆角 */
  min-width: 100px; /* 设置图片的最小宽度 */
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.value {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.8); /* 图片底部背景色 */
  padding: 5px 10px;
  border-radius: 5px;
  color: #3498db;
}

.number {
  font-size: 24px; /* 数字字体大小 */
}

.second-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.side-image img {
  width: 100px;
  height: auto;
  border-radius: 10px; /* 图片圆角 */
}

.side-content {
  margin-left: 20px;
}

.text-content {
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 48px;
  font-weight: 700;
  color: black;
}

.time-content {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 10px;
}
@media (max-width: 786px) {
  .header-item—list{
        width: 25%;
   }
  .container {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-around; /* 在手机端元素间的间距均分 */
  }


  .item {
    flex-basis: calc(33.33% - 20px); /* 在手机端每行显示三个，计算基础宽度 */
    margin: 10px; /* 调整元素间距 */
  }

  .image {
    width: 80px; /* 调整图片宽度 */
  }

  .value {
    font-size: 12px; /* 调整数字字体大小 */
  }

  .number {
    font-size: 16px; /* 调整数字字体大小 */
  }

  .second-container {
    margin-top: 40px; /* 调整第二个容器的上边距 */
  }

  .side-image img {
    width: 60px; /* 调整第二个容器图片宽度 */
  }

  .text-content {
    font-size: 24px; /* 调整文本内容字体大小 */
  }

  .time-content {
    font-size: 14px; /* 调整时间字体大小 */
  }
}

</style>

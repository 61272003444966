// src/router/index.js
import Vue from 'vue';
import Router from 'vue-router';

// 导入组件
import Home from '@/views/Home.vue';
import About from '@/views/About.vue';

// 使用 Router 插件
Vue.use(Router);

// 定义路由规则
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  }
];

// 创建路由实例
const router = new Router({
  mode: 'history', // 可选项: 'hash' | 'history'
  routes
});

export default router;

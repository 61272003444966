class WebSocketService {
    constructor(url) {
      this.url = url;
      this.socket = null;
    }
  
    connect() {
      this.socket = new WebSocket(this.url);
  
      this.socket.onopen = () => {
        console.log('WebSocket connected');
      };
  
      this.socket.onmessage = (event) => {
      };
  
      this.socket.onclose = () => {
        console.log('WebSocket closed');
      };
  
      this.socket.onerror = (error) => {
        console.error('WebSocket error', error);
      };
    }
  
    sendMessage(message) {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(message);
      } else {
        console.error('WebSocket is not open');
      }
    }
  
    disconnect() {
      if (this.socket) {
        this.socket.close();
      }
    }
  }
  
  export default WebSocketService;
  